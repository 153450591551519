import moment from "moment";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ReadDataStyled } from "./styled";
import { t } from "i18next";
import TopFilter from "../common/TopFilter/TopFilter";
import parseObjectToUrlParams from "../../../utils/parseObjectToUrlParams";
import AuthStore from "../../../stores/auth/auth.store";
import axios from "axios"; // Moved this to the top for better readability
import { DatePicker, Divider } from "antd";
import { cn } from "../../../utils";

interface Props extends RouteComponentProps<any> {
  authStore?: AuthStore;
}

@inject("authStore")
@observer
class SlumpChanges extends React.PureComponent<Props> {
  @observable data: any = [];
  @observable filters: any = {};
  @observable displayMode: "month" | "week" | "day" = "month";
  @observable activeSlump:any = '' 
  @observable dateFormat = 'DD/MM/YYYY';
  @observable currentDate: any = '25/09/2024/' ;
  @observable group_id = this.props.authStore!.getUserGroipID();

  componentDidMount = async () => {
    await this.fetchData();
  };

  fetchData = async () => {
    try {

      const rsp = await axios.get(
        `${process.env.REACT_APP_API_URL}/mixtures_behavior/calculate/${this.group_id}`
      );
      this.data = rsp.data.data; // Ensure data is assigned properly


      const current_date= this.data[0]?.date;
      this.currentDate  = moment(current_date).format('DD/MM/YYYY/');

    
      console.log({ma:this.currentDate})
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  onChange = async (date:any, dateString:string) => {
    console.log({date, dateString});
    this.filters.date = dateString;
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/mixtures_behavior/calculate/${this.group_id}?` +
      parseObjectToUrlParams(this.filters)
    );
    this.data = response.data.data; 
  };



   slumStatus = ( range:number ) => {
    // Function to switch between class names based on the value of condition / 25

    const result = Math.abs(range) / 25;  // Get absolute value of condition and divide by 25
    
    if (result < 1) {
      return 'box-items green';
    } else if (result >= 1 && result <= 2) {
      return 'box-items orange';
    } else {
      return 'box-items red';
    }


  };
  

  render() {
    return (
      <ReadDataStyled>
        <div className="p-5 flex flex-col">

   
          <div className="flex gap-4 m-5">
          <DatePicker onChange={this.onChange} defaultValue={moment(this.currentDate, this.dateFormat)} format={this.dateFormat} />

          <div className="rounded-md overflow-hidden">
                  <button
                    className={cn(
                      "rounded-none bg-slate-400 hover:bg-slate-500",
                      {
                        "bg-main-orange hover:bg-orange-500":
                          this.displayMode === "month",
                      }
                    )}
                    onClick={() => (this.displayMode = "month")}
                  >
                    {t("month")}
                  </button>
                  <button
                    className={cn(
                      "rounded-none bg-slate-400 hover:bg-slate-500",
                      {
                        "bg-main-orange hover:bg-orange-500":
                          this.displayMode === "week",
                      }
                    )}
                    onClick={() => (this.displayMode = "week")}
                  >
                   {t("week")} 
                  </button>
                  <button
                    className={cn(
                      "rounded-none bg-slate-400 hover:bg-slate-500",
                      {
                        "bg-main-orange hover:bg-orange-500":
                          this.displayMode === "day",
                      }
                    )}
                    onClick={() => { (this.displayMode = "day")}}
                  >
                  {t("day")}
                  </button>
                </div>
          </div>
          <div className="flex mx-5">
            <h1 className="text-2xl font-semibold">{t("mixDesignBehavior")}</h1>
          </div>
          
        
          <div className="slump-statuses">
            {this.data?.map((raw: any) => (
             
              

              <div className={this.slumStatus(
                this.displayMode === "day" ? raw.slump_deviation_day : 
                this.displayMode === "week" ? raw.slump_deviation_week : 
                this.displayMode === "month" ? raw.slump_deviation_month : null
              )} key={raw.id}>
                <h3 className="bold text-center">{raw.mixture_name}</h3> {/* Example property */}
                <p className="text-center black">{raw.mixture_code}</p> {/* Example property */}


                <Divider  />
                <div className="mx-auto">
                  <table className="min-w-full">
                    {this.displayMode === "month" && (
                      <div className="flex flex-col">
                        <div className="flex gap-3">
                          <svg  width="20" className="mt-1" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1044_7625)">
                            <path d="M18.6668 18.6663H2.00016C1.63197 18.6663 1.3335 18.3679 1.3335 17.9997V1.33301M4.66683 15.333L17.3335 2.66634M17.3335 2.66634V6.66634M17.3335 2.66634H13.3335" stroke="#FF6700" stroke-width="2" stroke-linecap="round"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_1044_7625">
                            <rect width="20" height="20" fill="white"/>
                            </clipPath>
                            </defs>
                          </svg>

                          <div className="black text-xl bold">
                            {raw.slump_deviation_month !== null ? raw.slump_deviation_month + ' ' + t("mm") : '-'}
                          </div>
                          
                        </div>
                       
                        
                        <div className="black text-xl">
                          {raw.number_of_deliveries_month !== null ? raw.number_of_deliveries_month + ' ' + t("deliveries") : `0  ${t("deliveries")}`}
                        </div>
                      </div>
                    )}

                    {this.displayMode === "week" && (
                      <div className="flex flex-col">
                       

                        <div className="flex gap-3">
                          <svg  width="20" className="mt-1" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1044_7625)">
                            <path d="M18.6668 18.6663H2.00016C1.63197 18.6663 1.3335 18.3679 1.3335 17.9997V1.33301M4.66683 15.333L17.3335 2.66634M17.3335 2.66634V6.66634M17.3335 2.66634H13.3335" stroke="#FF6700" stroke-width="2" stroke-linecap="round"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_1044_7625">
                            <rect width="20" height="20" fill="white"/>
                            </clipPath>
                            </defs>
                          </svg>

                          <div className="black text-xl bold">
                          {raw.slump_deviation_week !== null ? raw.slump_deviation_week + ' ' + t("mm") : '-'}
                          </div>
                          
                        </div>

                        
                        <div className="black text-xl">
                          {raw.number_of_deliveries_week !== null ? raw.number_of_deliveries_week + ' ' + t("deliveries") :`0  ${t("deliveries")}`}
                        </div>
                      </div>
                    )}

                    {this.displayMode === "day" && (
                      <div className="flex flex-col">
                        
                        <div className="flex gap-3">
                          <svg  width="20" className="mt-1" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1044_7625)">
                            <path d="M18.6668 18.6663H2.00016C1.63197 18.6663 1.3335 18.3679 1.3335 17.9997V1.33301M4.66683 15.333L17.3335 2.66634M17.3335 2.66634V6.66634M17.3335 2.66634H13.3335" stroke="#FF6700" stroke-width="2" stroke-linecap="round"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_1044_7625">
                            <rect width="20" height="20" fill="white"/>
                            </clipPath>
                            </defs>
                          </svg>

                          <div className="black text-xl bold">
                            {raw.slump_deviation_day !== null ? raw.slump_deviation_day + ' ' + t("mm") : '-'}
                          </div>
                          
                        </div>


                        
                        <div className="black text-xl">
                          {raw.number_of_deliveries_day !== null ? raw.number_of_deliveries_day + ' ' + t("deliveries") : `0  ${t("deliveries")}`}
                        </div>
                      </div>
                    )}
                  </table>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ReadDataStyled>
    );
  }
}

export default withRouter(SlumpChanges);
